import Datepicker from 'flowbite-datepicker/Datepicker'

const datepickerEl = document.querySelectorAll('.date_picker')

datepickerEl.forEach(datepickerEl => {
	new Datepicker(datepickerEl, {
		autohide: true,
		todayHighlight: true,
		format: 'dd/mm/yyyy'
	})
})
// Validation

const submitButton = document.querySelector('#submitBtn')

const checkRequiredFields = e => {
	const requiredFields = document.querySelectorAll('.form-to-validation .required')
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	const phoneNumberRegex = /^[0-9+()\s-]+$/
	const loader = document.querySelector('.loader')
	let isCorrect = true
	requiredFields.forEach(input => {
		const errInfo = input.parentElement.querySelector('p')
		errInfo.style.color = 'red'
		errInfo.classList.add('text-center')
		errInfo.innerHTML = ''
		if (!input.value.trim() && input.type !== 'checkbox') {
			e.preventDefault()
			errInfo.innerHTML = 'Uzupełnij wymagane pole'
			isCorrect = false
		}

		if (input.value.trim() && input.type === 'email') {
			if (!emailRegex.test(input.value)) {
				e.preventDefault()
				errInfo.innerHTML = 'Podaj poprawny adres e-mail'
				isCorrect = false
			}
		}

		if (input.value.trim() && input.type === 'tel') {
			if (!phoneNumberRegex.test(input.value)) {
				e.preventDefault()
				errInfo.innerHTML = 'Podaj poprawny numer telefonu'
				isCorrect = false
			}
		}

		if (input.type === 'file') {
			if (input.files.length === 0) {
				e.preventDefault()
				errInfo.innerHTML = 'Dodaj załącznik z CV'
				isCorrect = false
			} else if (input.files[0].size > 5242880) {
				console.log(input)
				e.preventDefault()
				errInfo.innerHTML = 'Rozmiar pliku nie powinien przekraczać 5 MB'
				isCorrect = false
			}
		}

		if (input.type === 'checkbox' && !input.checked) {
			e.preventDefault()
			errInfo.innerHTML = 'Zaznacz wymagane pole'
			isCorrect = false
		}
	})

	isCorrect ? loader.classList.remove('hidden') : loader.classList.add('hidden')
}

submitButton.addEventListener('click', e => {
	checkRequiredFields(e)
})

document.addEventListener('DOMContentLoaded', function () {
	document.getElementById('reservationForm').addEventListener('submit', async function (e) {
		e.preventDefault()

		// Show the loader
		document.querySelector('.loader').classList.remove('hidden')

		const formData = new FormData(this)
		const response = await fetch(this.action, {
			method: 'POST',
			body: formData
		})

		const result = await response.json()

		// Hide the loader
		document.querySelector('.loader').classList.add('hidden')
		const filledInputs = document.querySelectorAll('.form-to-validation input')
		const responseMessage = document.querySelector('.response')
		if (result.success) {
			responseMessage.innerHTML = '<p class="text-green-500">' + result.message + '</p>'
			filledInputs.forEach(input => {
				input.value = ''
			})
		} else {
			responseMessage.innerHTML = '<p class="text-red-500">' + result.message + '</p>'
		}
		responseMessage.classList.remove('hidden')
		setTimeout(() => {
			responseMessage.classList.add('hidden')
		}, 5000)
		turnstile.reset('#rentissopot-turnstile')
	})
})

// MODAL

const modal = document.querySelector('.modal')
const footer = document.querySelector('footer')
const banner = document.querySelector('.banner')
const bannerDistance = banner.getBoundingClientRect().top
const footerDistance = footer.getBoundingClientRect().top

document.addEventListener('scroll', e => {
	if (window.scrollY >= bannerDistance && window.scrollY <= footerDistance - window.innerHeight) {
		modal.classList.remove('translate-x-full')
	} else {
		modal.classList.add('translate-x-full')
	}
})
